export const COLX_TOKEN = {
  name: "COLX Token",
  symbol: "COLX",
  decimal: 8,
  address: "0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb",
};

// export const CHAIN_ID = "0x61"; // bnb testnet
export const CHAIN_ID = "0x38"; // bnb mainnet

export const COLX_BRIDGE_ADDRESS = "0x6850E5D5396e4ce3CB6919E790b8Fce2Ebdf49eC";

export const NETWORK_DETAILS = {
  chainId: CHAIN_ID,
  chainName: "BNB Smart Chain Mainnet",
  nativeCurrency: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: ["https://bsc-dataseed1.binance.org/"],
  blockExplorerUrls: ["https://bscscan.com"],
};
