import { ethers } from "ethers";
import { COLX_TOKEN, NETWORK_DETAILS, COLX_BRIDGE_ADDRESS } from "./constants";
import { ERC20_ABI } from "./erc20Abi";
import { CONTRACT_ABI } from "./contractAbi";

const getAccount = async () => {
  let value = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  await changeNetwork();
  return value;
};

export const changeNetwork = async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: NETWORK_DETAILS.chainId }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [NETWORK_DETAILS],
          });
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: NETWORK_DETAILS.chainId }],
          });
        } catch (addError) {
          console.log("Failed to add the network to MetaMask:", addError);
        }
      } else {
        console.log("Error switching network:", error);
      }
    }
  }
};

const handleChainChanged = async () => {
  await changeNetwork();
};

if (window.ethereum) {
  window.ethereum.on("chainChanged", handleChainChanged);
}

export const getSignerAddress = async () => {
  const signerAddressArray = await getAccount();
  let signerAddress = await signerAddressArray[0];
  return signerAddress;
};

export const isValidAddress = (receiversAddress) => {
  try {
    ethers.utils.getAddress(receiversAddress);
    return true;
  } catch (error) {
    return false;
  }
};

export const getBNBBalance = async () => {
  try {
    const address = await getSignerAddress();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(address);
    const balanceInBNB = ethers.utils.formatEther(balance);
    return balanceInBNB;
  } catch (error) {
    console.log("Error getting BNB balance:", error);
    return null;
  }
};

export const getTokenBalance = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const signer = provider.getSigner();

  let tokenContract = new ethers.Contract(
    COLX_TOKEN.address,
    ERC20_ABI,
    signer
  );
  const signerAdd = await signer.getAddress();
  const balance = await tokenContract.balanceOf(signerAdd);
  const balanceInWei = balance.toString();
  const balanceInDecimals = ethers.utils.formatUnits(balanceInWei, 8);
  return balanceInDecimals;
};

export const getGasPrice = async (toAddress, amount) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let tokenContract = new ethers.Contract(
      COLX_BRIDGE_ADDRESS,
      CONTRACT_ABI,
      signer
    );
    const parsedAmount = ethers.utils.parseUnits(amount.toString(), 18);
    const estimatedGas = await tokenContract.estimateSendFee(
      110,
      toAddress,
      parsedAmount,
      false,
      "0x000100000000000000000000000000000000000000000000000000000000000493e0"
    );
    return estimatedGas;
  } catch (error) {
    console.log("Error fetching gas price:", error);
    return null;
  }
};
