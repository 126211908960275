import React, { useContext } from "react";
import "./App.css";
import ConnectWallet from "./components/ConnectWallet";
import SendToken from "./components/SendToken";
import { WalletContext } from "./context/WalletContext";
import { NavigationBar } from "./components/NavigationBar";
import TransactionHistoryTable from "./components/TransactionHistory";

const App = () => {
  const { address } = useContext(WalletContext);

  return (
    <div className="app">
      <div>
        <NavigationBar />
      </div>
      {address && <SendToken />}
    </div>
  );
};

export default App;
