import React, { useState, useContext } from "react";
import { Col, Input, Row } from "reactstrap";
import { BsChevronDown } from "react-icons/bs";
import GasPrice from "./GasPrice";
import { WalletContext } from "../../context/WalletContext";

const GasDetails = ({ gasLimit, setGasLimit }) => {
  const [showGasLimitInput, setShowGasLimitInput] = useState(false);
  const [showGasFeeInput, setShowGasFeeInput] = useState(false);
  const { currentGasPrice, setCurrentGasPrice } = useContext(WalletContext);
  const toggleGasFeeInput = () => {
    setShowGasFeeInput(!showGasFeeInput);
  };
  const toggleGasLimitInput = () => {
    setShowGasLimitInput(!showGasLimitInput);
  };
  return (
    <>
      <Row>
        <Col style={{ color: "#158DE8", display: "flex" }} xs={8}>
          Estd Gas Fee:
        </Col>
        <div style={{ color: "#fff", width: "100px" }}>
          <GasPrice />
        </div>
      </Row>
      <Row>
        <Col xs={12} className="heading">
          <div className="gas-limit-container">
            <div className="gas-dropdown-container">
              <div className="gas-limit-toggle" onClick={toggleGasLimitInput}>
                Set Gas Limit <BsChevronDown />
              </div>
              <div className="gas-limit-toggle" onClick={toggleGasFeeInput}>
                Set Gas Fee <BsChevronDown />
              </div>
            </div>
            {showGasLimitInput && (
              <Input
                style={{ marginBottom: "5px" }}
                className="inputValue"
                placeholder="Gas Limit"
                type="number"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
              />
            )}

            {showGasFeeInput && (
              <Input
                className="inputValue"
                placeholder="Gas Fee"
                type="number"
                value={currentGasPrice}
                onChange={(e) => setCurrentGasPrice(e.target.value)}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GasDetails;
