import React, { useContext } from "react";
import ConnectWallet from "./ConnectWallet";
import { Navbar, NavbarBrand, Nav } from "reactstrap";
import { WalletContext } from "../context/WalletContext";
import formatAddress from "../utils/formats";

export const NavigationBar = () => {
  const { address, tokenBal, bnbBal, disconnectWallet } =
    useContext(WalletContext);
  return (
    <div>
      <Navbar className="navbar">
        <NavbarBrand href="/">Colx One Way Bridge from BSC to Arbitrum</NavbarBrand>
        <Nav className="navbar-right">
          <ConnectWallet />
          {address && <div>Address : {`${formatAddress(address)}`}</div>}
        </Nav>
      </Navbar>
    </div>
  );
};
