import React, { useContext, useState } from "react";
import { BeatLoader } from "react-spinners";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { WalletContext } from "../../context/WalletContext";
import { sendTransaction } from "../../utils/sendTransaction";
import GasDetails from "../GasDetails";
import TransactionAlert from "../TransactionAlert";
import WalletDetails from "./WalletDetails";
import ConnectWallet from "../ConnectWallet";
import TransactionHistoryTable from "../TransactionHistory";

const SendToken = () => {
  const {
    address,
    tokenBal,
    currentGasPrice,
    setTransactionHash,
    transactionList,
  } = useContext(WalletContext);
  const [amount, setAmount] = useState(0);
  const [receiversAddress, setReceiversAddress] = useState(address);
  const [refundAddress, setRefundAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(Date.now());
  const [transactionConfirmed, setTransactionConfirmed] = useState(null);
  const [gasLimit, setGasLimit] = useState(10000000);
  const [gasFee, setGasFee] = useState(10000000);
  const [invalidAmount, setInvalidAmount] = useState(false);
  const [showRefundAddress, setShowRefundAddress] = useState(false);

  const handleSendTransaction = async () => {
    setInvalidAmount(parseFloat(amount) > parseFloat(tokenBal));
    try {
      setLoading(true);
      await sendTransaction(
        currentGasPrice,
        address,
        amount,
        receiversAddress,
        gasLimit,
        setTransactionHash,
        storeTransactionHash
      );
      setTransactionConfirmed(true);
      setLastRefreshTime(Date.now()); // Update the last refresh time
    } catch (error) {
      console.log("Error sending transaction:", error);
    } finally {
      setLoading(false);
    }
  };

  const storeTransactionHash = (address, hash) => {
    const transactions = JSON.parse(localStorage.getItem(address)) || [];
    transactions.unshift(hash);
    localStorage.setItem(address, JSON.stringify(transactions));
  };

  return (
    <>
      <Card
        style={{
          fontWeight: "bold",
          margin: "auto",
          background: "rgb(30 36 49)",
          paddingBottom: "24px",
          marginTop: "10%",
        }}
      >
        <div style={{ width: "358px", color: "white !important" }}>
          <CardBody>
            <WalletDetails />
            <Row>
              <Col xs={12} className="heading">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="chain-card">From BNB</div>
                  <div
                    style={{
                      marginTop: "12px",
                      color: "rgb(106, 106, 106)",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    {`->`}
                  </div>
                  <div className="chain-card">To Arb</div>
                </div>
              </Col>
            </Row>
            <FormGroup check>
              <Label style={{ color: "rgba(255,255,255,0.9)" }}>
                Send to different address
                <Input
                  type="checkbox"
                  checked={showRefundAddress}
                  onChange={(e) => setShowRefundAddress(e.target.checked)}
                />
              </Label>
            </FormGroup>
            {showRefundAddress && (
              <Row>
                <Col xs={12} className="heading">
                  <Input
                    className="inputValue"
                    placeholder="Add Address"
                    type="text"
                    onChange={(e) => setRefundAddress(e.target.value)}
                  />
                </Col>
              </Row>
            )}
            <GasDetails gasLimit={gasLimit} setGasLimit={setGasLimit} />
            <div
              style={{ color: "white", marginLeft: "10px", marginTop: "20px" }}
            >
              Add token amount
            </div>
            <Row>
              <Col xs={8}>
                <Input
                  placeholder="Amount"
                  type="number"
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setInvalidAmount(parseFloat(amount) > parseFloat(tokenBal));
                  }}
                  style={{ color: invalidAmount ? "red" : "inherit" }}
                />
                {invalidAmount && (
                  <div style={{ color: "red" }}>
                    Amount is greater than balance
                  </div>
                )}
              </Col>
              <Col xs={4}>
                <Button
                  color="primary"
                  block
                  onClick={handleSendTransaction}
                  disabled={loading || invalidAmount || amount <= 0}
                >
                  {loading ? (
                    <BeatLoader size={8} color="#ffffff" loading={loading} />
                  ) : (
                    "Send"
                  )}
                </Button>
              </Col>
            </Row>
            {!address && <ConnectWallet />}
          </CardBody>
        </div>
      </Card>
      {transactionList && (
        <TransactionHistoryTable lastRefreshTime={lastRefreshTime} />
      )}
      <TransactionAlert transactionConfirmed={transactionConfirmed} />
    </>
  );
};

export default SendToken;
