import React, { useState, useEffect, useContext } from "react";
import { Col } from "reactstrap";
import { WalletContext } from "../../context/WalletContext";
import { getGasPrice } from "../../utils/ethersUtils";
import { ethers } from "ethers";

const GasPrice = () => {
  const { address, currentGasPrice, setCurrentGasPrice } =
    useContext(WalletContext);

  useEffect(() => {
    const fetchGasPrice = async () => {
      try {
        if (address) {
          const paddedAddress = ethers.utils.hexZeroPad(
            "0x85392e765680737b29e449faf37df956f0931f58",
            32
          );

          const gasPrice = await getGasPrice(paddedAddress, 1);
          setCurrentGasPrice(parseInt(gasPrice));
        }
      } catch (error) {
        console.log(`Error fetching gas price:, ${error}`);
      }
    };
    fetchGasPrice();
  }, [address]);

  return (
    <div style={{ width: "100px" }}>{`${(
      parseInt(currentGasPrice) /
      10 ** 18
    ).toFixed(5)} BNB`}</div>
  );
};

export default GasPrice;
