import BootstrapTable from "react-bootstrap-table-next";
import { useContext, useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { WalletContext } from "../context/WalletContext";

const columns = [
  {
    dataField: "index",
    text: "Index",
  },
  {
    dataField: "transactionHash",
    text: "Transaction Hash",
    formatter: (cellContent, row) => (
      <a
        href={`https://layerzeroscan.com/tx/${cellContent}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "rgb(45 53 73)" }}
      >
        {cellContent}
      </a>
    ),
  },
];

const TransactionHistoryTable = ({ lastRefreshTime }) => {
  const { address, transactionList, setTransactionList } =
    useContext(WalletContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = () => {
      setIsLoading(true);
      const transactions = JSON.parse(localStorage.getItem(address)) || [];
      const formattedTransactions = transactions.map((hash, index) => ({
        index: transactions.length - index,
        transactionHash: hash,
      }));
      setTransactionList(formattedTransactions);
      setIsLoading(false);
    };

    if (address) {
      fetchTransactions();
    }
  }, [address, lastRefreshTime, setTransactionList]);

  return isLoading ? (
    <span className="m-auto mt-3" style={{ display: "flex", color: "white" }}>
      Loading Transactions <SyncLoader size={10} />
    </span>
  ) : (
    <div
      className="container"
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      Transaction History
      <div>
        <BootstrapTable
          striped
          hover
          keyField="index"
          data={transactionList}
          columns={columns}
          rowStyle={{ color: "blue !important" }}
          wrapperClasses="table-blue-text"
        />
      </div>
    </div>
  );
};

export default TransactionHistoryTable;
