import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import { WalletContext } from "../../context/WalletContext";

const WalletDetails = () => {
  const { tokenBal, bnbBal } = useContext(WalletContext);

  return (
    <>
      {" "}
      <div className="card-title"> BRDIGE</div>
      <Row>
        <Col sm={6} className="heading">
          BNB Balance:
        </Col>
        <Col sm={6} className="value">
          {`${parseFloat(bnbBal).toFixed(5)} BNB`}
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="heading">
          Colx Balance:
        </Col>
        <Col sm={6} className="value">
          {` ${tokenBal} Colx`}
        </Col>
      </Row>
    </>
  );
};

export default WalletDetails;
